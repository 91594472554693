import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { title: 'PPP-Programación' }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {requiresAuth: true,title: 'PPP-Programación'}
  },
  {
    path: '/productos',
    name: 'Productos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/productos/Productos.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/cotizaciones',
    name: 'Cotizaciones',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cotizaciones/Cotizaciones.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/producto/cotizar',
    name: 'Cotizar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cotizar/Cotizar.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/producto/cotizar/:producto_id',
    name: 'CotizarProductoId',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cotizar/Cotizar.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/producto/configurar/:producto_id',
    name: 'ConfigurarProducto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/productos/Configurar.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/carrito/listaProductos',
    name: 'ListaProductos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cart/CartView.vue'),
    meta: {requiresAuth: true}
  },
  /**ADMINISTRADOR**/
  {
    path: '/administrador/clientes',
    name: 'Clientes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/administrador/clientes/Clientes.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/administrador/piezas',
    name: 'Piezas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/administrador/piezas/Piezas.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/administrador/propiedades',
    name: 'Propiedades',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/administrador/propiedades/Propiedades.vue'),
    meta: {requiresAuth: true}
  },
  /***************/
  /***ADMIN SISTEMA***/
  {
    path: '/admin/roles',
    name: 'Roles',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/roles/Roles.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/admin/grupo_permisos',
    name: 'GrupoPermisos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/grupo_permisos/GrupoPermisos.vue'),
    meta: {requiresAuth: true}
  },

  {
    path: '/admin/permisos',
    name: 'Permisos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/permisos/Permisos.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/admin/usuarios',
    name: 'Usuarios',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/usuarios/Usuarios.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/admin/usuarios/editar/:usuario_id',
    name: 'EditarUsuario',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/usuarios/Editar.vue'),
    meta: {requiresAuth: true}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router
